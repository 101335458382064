import {Component, Prop, Vue, Watch} from 'vue-property-decorator'

@Component({
    name: 'popin-welcome'
})
export default class PopinWelcome extends Vue {

  no_display = localStorage.getItem('no-display-migration') === 'true';

  setNoDisplay() {
    localStorage.setItem('no-display-migration', this.no_display.toString());
  }

  close() {
    this.$bvModal.hide('popin-welcome-modal');
  }
}
