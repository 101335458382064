import {Action, Module, Mutation, VuexModule} from 'vuex-module-decorators'
import {LoginProfil, Profil, Subscription, OfferSubscribed} from '@/types/AccountState'
import API from "@/api/wrapper";
import {
    Session_GET_APIInterface,
    Users_Login_Roles_GET_APIInterface,
    Users_Login_Roles_GET_APIQueryInterface,
    Users_Subscription_GET_APIQueryInterface,
    Users_Login_Profile_type_PUT_APIInterface,
    Users_Login_Profile_type_PUT_APIQueryInterface
} from "@/api/generated/types";
import {
    Session_GET_APIClass,
    Users_Login_Roles_GET_APIClass,
    Users_Subscription_GET_APIClass,
    Users_Login_Profile_type_PUT_APIClass
} from "@/api/generated/classes";
import {ENUM_PROFILE_TYPE} from "@/api/generated/enums";
import Store from '@/store' ;
import ErrorService from '@/services/ErrorService';

interface DataProfil {
    profile_type:(string | ENUM_PROFILE_TYPE)[];
    [key: string]: string | boolean | undefined | string[];
}
export class ProfilClass implements Profil {
    profile_type : (string | ENUM_PROFILE_TYPE) []= [];
    title : string = '';
    login : string = '';
    first_name : string = '';
    last_name : string = '';
    email : string = '';
    email_rescue : string = '';
    paying : boolean = false;
    department : string = '';
    position : string = '';
    safebox_cc : string = '';
    company_name : string = '';
    country_code : string;
    address1 : string = '';
    address2 : string = '';
    address3 : string = '';
    zipcode : string = '';
    city : string = '';
    state : string = '';
    duns : string = '';
    siret : string = '';
    eu_vat_number : string = '';
    phone_office : string = '';
    rights : string[] = [];
    safebox : boolean = false;
    safebox_contract_identifier : string = '';
    safebox_signed_contract_return_date : string = '';
    safebox_max_return_date : string = '';
    qsds_consent : boolean = false;
    [key: string]: string | boolean | string[] | undefined;
}
export class SubscriptionClass implements Subscription {
    tokens : number = 0;
    used_tokens : number = 0;
    total_results : number = 0;
    offers : OfferSubscribed[] = [];
    [key: string]: string | number | OfferSubscribed[];
}
@Module({
    namespaced: true
})
export default class AccountState extends VuexModule {
    // STATES
    _isLoggedIn: boolean = false;
    _isAdmin: boolean = false;
    _canFreeConsult: boolean = false;
    _hasSubscription: boolean = false;
    _hasSubscriptionPending: boolean = false;

    _profil: Profil = new ProfilClass;
    _subscription: Subscription = new SubscriptionClass;
    _loading: boolean = true;

    // MUTATIONS
    @Mutation
    logout () {
        this._isLoggedIn = false;
        this._isAdmin = false;
        this._hasSubscription = false;
        this._hasSubscriptionPending = false;

        this._profil = new ProfilClass;
        this._subscription = new SubscriptionClass;
    }

    @Mutation
    gotProfil(profil: DataProfil) {
        for (const key in profil) {
            const value = profil[key];
            if (Object.prototype.hasOwnProperty.call(this._profil, key)) {
                this._profil[key] = value;
            }
        }
        this._isLoggedIn = !this._profil.profile_type.includes(ENUM_PROFILE_TYPE.GUEST);
        this._isAdmin = this._profil.profile_type.includes(ENUM_PROFILE_TYPE.ADMIN);
    }

    @Mutation
    gotSubscription(subscription:any) {
        for (const key in subscription) {
            const value = subscription[key];
            if (Object.prototype.hasOwnProperty.call(this._subscription, key)) {
                this._subscription[key] = value;
            }
            //this._subscription[key] = value;
        }
        if (this._subscription.offers.length) {
            this._hasSubscription = true;
            //on met à jour la quantité minimum
            Store.commit('shopping/setMinimumQuantity', this._subscription.offers[0].packs);

            // looking for any offer pending
           this._hasSubscriptionPending = this._subscription.offers.some(offer => offer.pending)
        } else {
            this._hasSubscription = false;
        }
    }
    @Mutation
    setSubscriptionPending(hasSubscriptionPending:boolean) {
        this._hasSubscriptionPending = hasSubscriptionPending;
    }

    @Mutation
    gotFreeConsultPass() {
        this._canFreeConsult = true;
    }

    @Mutation
    gotLoading(newLoading:boolean) {
        this._loading = newLoading;
    }

    static parseData (dataParsed: DataProfil, datas:{name: string, value: string | boolean}[]) {
        for (const data of datas) {
            const name = data.name;
            const value = data.value;
            if (name === 'profile_type_code') {
                dataParsed.profile_type.push(String (value));
            } else {
                dataParsed[name] = value;
            }
        }
    }
    static parseDataSubscription(dataParsed:any, datas:{name: string, value: string | boolean}[]) {
        for (const data of datas) {
            const name = data.name;
            const value = data.value;
            dataParsed[name] = value;
        }
    }

    // ACTIONS
    @Action
    login (loginProfil: LoginProfil) {
        this.context.commit('gotProfil', {login: loginProfil.login ? loginProfil.login : ''});
        return this.context.dispatch('getDatas');
    }

    @Action
    getSubscription() {
        const query: Users_Subscription_GET_APIQueryInterface = {
            login: this.profil.login
        };
        const APIClass: Users_Subscription_GET_APIClass = new Users_Subscription_GET_APIClass(query);
        return API.user.getSubscription(
                APIClass,
                (response:any) => {
                    if (response.data.collection.items) {
                        const dataParsed: Subscription = {
                            tokens: 0,
                            used_tokens: 0,
                            total_results: 0,
                            offers: []
                        };
                        for (const item of response.data.collection.items) {
                            AccountState.parseDataSubscription(dataParsed, item.data)
                            if (item.items) {
                                for (const subItem of item.items) {
                                    const res:OfferSubscribed= {
                                        packs: 0,
                                        pending: false,
                                        offer_code: '',
                                        tool_id: 0,
                                        start_date: '',
                                        end_date: '',
                                        et_price: 0,
                                        offer_label: '',
                                        tool_label: '',
                                        individual_price: 0,
                                        state: ''
                                    };
                                    AccountState.parseDataSubscription(res, subItem.data)
                                    dataParsed.offers.push(res)
                                }
                            }
                        }
                        this.context.commit('gotSubscription', dataParsed);
                    }
                },
                (err: any) => {
                    ErrorService.onDefaultError(err);
                }
        )
    }

    @Action
    getDatas() {
        const paramsSession:Session_GET_APIInterface = {}
        const class_API_Session =  new Session_GET_APIClass(paramsSession);

        return API.callWithClass(
            class_API_Session,
            (response: any) => {
                const dataParsed: DataProfil = {
                    profile_type : []
                };
                AccountState.parseData (dataParsed, response.data.collection.items[0].data);
                for (const item of  response.data.collection.items[0].items) { // other
                    AccountState.parseData (dataParsed, item.data);
                }
                this.context.commit('gotProfil', dataParsed);
                if (this._isLoggedIn) {
                    const params:Users_Login_Roles_GET_APIInterface = {}
                    const query:Users_Login_Roles_GET_APIQueryInterface = {
                        login : this._profil.login
                    }
                    const class_API_Roles =  new Users_Login_Roles_GET_APIClass(query, params);

                    return API.callWithClass(
                        class_API_Roles,
                        (response: any) => {
                            const data = response.data.collection.items[0].data;
                            const dataParsed: {[key:string]:string} = {};
                            for (const d of data) {
                                dataParsed[d.name] = d.value;
                            }
                            this.context.commit('gotProfil', dataParsed);
                            this.context.dispatch('getSubscription');
                        },
                        (err: any) => {
                            ErrorService.onDefaultError(err);
                        }       
                    );
                }
            },
            (_err: any) => {
                alert("Suite à un problème technique vous n'êtes plus connecté");
                this.logout ();
            }
        );
    }
    subscribeSafebox() {
        const params:Users_Login_Profile_type_PUT_APIInterface = {safebox : true};
        const query:Users_Login_Profile_type_PUT_APIQueryInterface = {
            login : this._profil.login,
            profile_type : ENUM_PROFILE_TYPE.MYSDS
        };
        const class_API:Users_Login_Profile_type_PUT_APIClass = new Users_Login_Profile_type_PUT_APIClass(query, params);

        return API.user.modifyProfile(
            class_API,
            (res:string) => {
            },
            (err:string) => {
                ErrorService.onDefaultError(err);
            }
        );
    }

    // GETTERS
    get canFreeConsult(): boolean {
        return this._canFreeConsult;
    }

    get isLoggedIn():boolean {
        return this._isLoggedIn;
    }

    get isAdmin():boolean {
        return this._isAdmin;
    }

    get profil(): Profil {
        return this._profil;
    }

    get subscription(): any {
        return this._subscription;
    }
    get hasSubscription(): boolean {
        return this._hasSubscription;
    }
    get hasSubscriptionPending(): boolean {
        return this._hasSubscriptionPending;
    }

    get adminURL():string {
        return (process.env.VUE_APP_API_URL || window.location.origin + "/api/v1/")+'users/'+this._profil.login+'/admin';
    }

    get loading():boolean {
        return this._loading;
    }
    get titles() {
        return {MISTER:'M.', MISS:'Mme'}
    }
}
