// import Vue from 'vue'
import { Vue, Component, Watch} from 'vue-property-decorator'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

// import global fonts
import '@/assets/fonts/FontAwesome/css/all.min.css'
import '@/assets/fonts/Linearicons/Linearicons.css'
import '@/assets/fonts/OpenSans/OpenSans.css'

//import initiateurs
import API from "@/api/wrapper";
import {init as paramsCheckerInit} from '@/api/paramsChecker'

import packageJson from '@/../package.json';


@Component({
    name: 'app',
})
export default class App extends Vue {

    get codeError() {
        return this.$store.getters['navigation/codeError'];
    }

    @Watch('created')
    created() {
        console.log('Version', packageJson.version)
        this.init();
    }
    @Watch('mounted')
    mounted() {
        // Ajout du script de CookieBot au projet
        // on crée l'élément script à ajouter
        const cookieBotScript = document.createElement('script');
        cookieBotScript.setAttribute('id', 'CookieBot');
        cookieBotScript.setAttribute('src', 'https://consent.cookiebot.com/uc.js?cbid=571db31e-f23b-4924-a1a7-5eab5b561176');
        cookieBotScript.setAttribute('type', 'text/javascript');
        // ajout de l'élément HTML au document
        document.head.appendChild(cookieBotScript);
    }

    @Watch('codeError') 
    onCodeError() {
        if (this.codeError?.includes(556)) {
            this.autoReconnect();
        }
    }
    init() {
        this.$store.commit('account/gotLoading', true);
        const lang = this.$lang;
        API.init(this, undefined, this.$router, lang);
        this.autoReconnect();

        //window.addEventListener('scroll', this.onScroll);
        paramsCheckerInit();
        this.$store.commit('lang/init');
        this.$store.commit('shopping/init');
        this.$store.commit('search/initSearch');

        this.$store.dispatch('blog/fetchArticles');        
        this.$store.dispatch('tools/setCatalog');        
    }
    autoReconnect() {
        this.$store.dispatch('account/getDatas').then (() => {
            /*
            if (this.$store.getters['shopping/cartOffersId'].length) {
                this.$store.dispatch('shopping/updateCart');
            // if cart is empty
            } else {
                this.$store.dispatch('shopping/getCart');
            }
            */
            this.$store.commit('account/gotLoading', false);
        });
    }
}
    
